import Paper from "@mui/material/Paper";
import classnames from "classnames";
import PropTypes from "prop-types";

function BupPaper({ children, className, ...props }) {
  return (
    <Paper
      className={classnames("BupPaper-root", className)}
      elevation={1}
      square={false}
      variant="elevation"
      {...props}
    >
      {children}
    </Paper>
  );
}

BupPaper.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  elevation: PropTypes.number,
  square: PropTypes.bool,
  variant: PropTypes.oneOf(["elevation", "outlined"]),
  ...Paper.propTypes,
};

export default BupPaper;
