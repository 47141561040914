import Divider from "@mui/material/Divider";
import classnames from "classnames";
import PropTypes from "prop-types";

function BupDivider({ className, hidden, ...props }) {
  if (hidden) return null;

  return (
    <Divider className={classnames("Bup-divider", className)} {...props} />
  );
}

BupDivider.propTypes = {
  className: PropTypes.string,
  hidden: PropTypes.bool,
  ...Divider.propTypes,
};

export default BupDivider;
