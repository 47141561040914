import { Fragment } from "react";
import PropTypes from "prop-types";

const Component = ({ key, children }) => (
  <Fragment key={key}>{children}</Fragment>
);

Component.propTypes = {
  children: PropTypes.node,
  key: PropTypes.string,
};

export default Component;
