"use client";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import classnames from "classnames";
import { Icon, Fragment } from "components";

function BupIconButton({ className, onClick, variant, ...props }) {
  if (props.hidden) return null;

  const Wrapper = props.title ? Tooltip : Fragment;

  return (
    <Wrapper
      arrow={props.arrow}
      placement={props.placement}
      title={props.title}
    >
      <IconButton
        className={classnames("BupButton-root BupButton-icon", className, {
          "BupButton-circle": props.circle,
          "BupButton-square": props.square,
          "Bup-active": props.active,
          "Bup-disabled": props.disabled,
          "Bup-loading": props.loading,
        })}
        color={props.color}
        disabled={props.disabled}
        id={props.id}
        onClick={onClick}
        size={props.size || "medium"}
        title={props.name}
        variant={variant}
      >
        <Icon name={props.icon} variant={props.iconVariant} />
      </IconButton>
    </Wrapper>
  );
}

export default BupIconButton;
