"use client";

import { useEffect, useState } from "react";
import classnames from "classnames";

function Background({ children, className, src, url, ...props }) {
  const [source, setSource] = useState("");

  useEffect(() => {
    const image = new Image();
    image.src = url || "/" + src;
    image.onload = () => setSource(image.src);
  }, [src, url]);

  return (
    <div
      className={classnames("BupImage-lazy", className)}
      style={{ backgroundImage: `url(${source})` }}
      {...props}
    >
      {children}
    </div>
  );
}

export default Background;
