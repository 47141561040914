import { Box, Logo } from "components";

function Loading() {
  return (
    <Box className="BupLoading-root" component="main">
      <Logo />
    </Box>
  );
}

export default Loading;
