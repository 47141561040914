"use client";

import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import { styled } from "@mui/material/styles";
import classnames from "classnames";
import { Icon } from "components";
import { useState } from "react";

function BupAlert({ children, className, ...props }) {
  const [open, setOpen] = useState(true);

  const handleClose = () => {
    setOpen(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  const CustomAlert = styled(Alert)(({ theme }) => ({
    backgroundColor: `${theme.palette.premium.main}33`,
    color: theme.palette.premium.contrastText,
  }));

  return (
    !props.hidden && (
      <Collapse in={open} {...(props.disableTransition && { timeout: 0 })}>
        {props.severity === "premium" ? (
          <CustomAlert
            action={
              props.onClose && (
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleClose}
                >
                  <Icon name="close" fontSize="inherit" />
                </IconButton>
              )
            }
            className={classnames("BupAlert-root Bup-premium", props.className)}
            severity="info"
            variant={props.variant}
            sx={{ mb: 2 }}
          >
            {props.title && <AlertTitle>{props.title}</AlertTitle>}
            {children}
          </CustomAlert>
        ) : (
          <Alert
            action={
              !props.disableCloseButton && (
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={handleClose}
                >
                  <Icon name="close" />
                </IconButton>
              )
            }
            className={classnames("BupAlert-root", className)}
            severity={props.severity}
            variant={props.variant}
            sx={{ mb: 2 }}
          >
            {props.title && <AlertTitle>{props.title}</AlertTitle>}
            {children}
          </Alert>
        )}
      </Collapse>
    )
  );
}

BupAlert.defaultProps = {
  className: "",
};

export default BupAlert;
