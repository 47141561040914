"use client";

import Button from "@mui/material/Button";
import classnames from "classnames";
import PropTypes from "prop-types";

function BupButton({
  active,
  children,
  className,
  hidden,
  loading,
  square,
  ...props
}) {
  if (hidden) return null;

  return (
    <Button
      className={classnames("BupButton-root", className, {
        "BupButton-square": square,
        "Bup-active": active,
        "Bup-disabled": props.disabled,
        "Bup-loading": loading,
      })}
      disableElevation
      size="medium"
      variant="contained"
      {...props}
    >
      {children}
    </Button>
  );
}

BupButton.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hidden: PropTypes.bool,
  loading: PropTypes.bool,
  ...Button.propTypes,
};

export default BupButton;
