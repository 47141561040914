"use client";

import classnames from "classnames";
import { Stack, Typography } from "components";
import PropTypes from "prop-types";

function Form({ className, children, form, title, titleVariant, ...props }) {
  return (
    !props.hidden && (
      <form
        className={classnames("BupForm-root", className)}
        onSubmit={form.handleSubmit}
        onReset={form.handleReset}
        {...props}
      >
        {title && (
          <Typography className="BupForm-title" variant={titleVariant}>
            {title}
          </Typography>
        )}
        <Stack spacing={2}>{children}</Stack>
      </form>
    )
  );
}

Form.defaultProps = {
  titleVariant: "h1",
};

Form.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  form: PropTypes.object.isRequired,
  title: PropTypes.string,
};

export default Form;
