import { DATE } from "data/consts";
import { currency, parser, str } from "utils";
import emojiRegex from "emoji-regex";
import moment from "moment";

function calculateDuration(startDateTime, endDateTime) {
  const startTime = moment(startDateTime);
  const endTime = moment(endDateTime);
  const duration = moment.duration(endTime.diff(startTime));
  const hours = Math.floor(duration.asHours());
  const minutes = duration.minutes();

  const hh = str.pluralize(hours, "hora");
  const mm = str.pluralize(minutes, "minuto");

  return `${hours ? hh : ""}${hours && minutes ? " y " : ""}${
    minutes ? mm : ""
  }`;
}

function getEventBoxDate(dateString) {
  const date = parser.toLocaleDate(dateString);
  const dayOfWeek = DATE.daysTranslations[date.format("dddd")].slice(0, 3);
  const dayOfMonth = date.format("D");
  const month = DATE.monthTranslations[date.format("MMMM")].slice(0, 3);

  return [dayOfWeek, dayOfMonth, month];
}

function getEventSchedule(event) {
  const startDateTime = parser.toLocaleDate(event.startDate);
  const endDateTime = parser.toLocaleDate(event.endDate);

  const startDate = startDateTime.format("DD [de] MMMM, YYYY");
  const startTime = startDateTime.format("HH:mm");
  const endDate = endDateTime.format("DD [de] MMMM, YYYY");
  const endTime = endDateTime.format("HH:mm");
  const duration = calculateDuration(startDateTime, endDateTime);
  const fullDate = getEventDate(event.startDate);

  return {
    duration,
    endDate,
    endTime,
    startDate,
    startTime,
    fullDate,
  };
}

function getEventDate(dateString) {
  const date = parser.toLocaleDate(dateString);
  const dayOfWeek = DATE.daysTranslations[date.format("dddd")];
  const dayOfMonth = date.format("D");
  const month = DATE.monthTranslations[date.format("MMMM")];
  const year = date.format("YYYY");

  return `${str.capitalize(dayOfWeek)} ${dayOfMonth} de ${month} de ${year}`;
}

function getEventTag(dateString) {
  const today = moment().startOf("day");
  const date = parser.toLocaleDate(dateString).startOf("day");

  if (date.isSame(today, "day")) {
    return "Hoy";
  } else if (date.isSame(today.clone().subtract(1, "day"), "day")) {
    return "Ayer";
  } else if (date.isSame(today.clone().add(1, "day"), "day")) {
    return "Mañana";
  } else {
    return null;
  }
}

function getEventPrice(eventConfig, discount) {
  const { feeType, price } = eventConfig;

  if (feeType.includes("paid") && discount > 0) {
    return currency(price * (1 - discount));
  }

  return currency(price);
}

function getEventPayment(eventConfig, discount, useShortType) {
  const { feeType, price } = eventConfig;

  if (price > 0) {
    return getEventPrice(eventConfig, discount);
  }

  if (feeType === "free") {
    return !useShortType ? "Evento gratuito" : "Gratuito";
  } else if (feeType === "free_payConsumption") {
    return !useShortType ? "Pago por consumo" : "Consumo";
  }

  return "";
}

function getEventAssistant(userLists, state) {
  return `${userLists.assistant?.length} ${
    state.isFinished ? "asistieron" : "asistirán"
  } • ${str.pluralize(userLists.interested?.length, "interesado")}`;
}

function getEventLocation(location, state) {
  if (!state.inPerson || !location.address || !location.name) return null;
  const address = location.address?.trim() || location.name?.trim();
  return `${str.abbreviate(address)}, ${location.commune}`;
}

function getEventName(name) {
  let textWithEmojis = name?.replace(/[“”'"]/g, "") || "";
  let textWithParenthesis = textWithEmojis.replace(/\([^)]*\)/g, "");
  let formattedName = textWithParenthesis.replace(emojiRegex(), "");

  return str.capitalize(formattedName, 1, 1);
}

const market = {
  getEventAssistant,
  getEventBoxDate,
  getEventLocation,
  getEventSchedule,
  getEventPayment,
  getEventPrice,
  getEventName,
  getEventTag,
};

export default market;
