/**
 * Abbreviate specific words in an address string.
 *
 * @param {string} address - The address string to abbreviate.
 * @returns {string} The abbreviated address string with specified words replaced by their abbreviations.
 */
function abbreviate(address) {
  // Initialize the abbreviated address with the original address
  let abbreviatedAddress = address;

  // Define an array of word replacements with their abbreviations
  const replacements = [
    { word: "Avenida", abbreviation: "Av." },
    { word: "Pasaje", abbreviation: "Pje." },
    { word: "General", abbreviation: "Gral." },
    { word: "Edificio", abbreviation: "Edif." },
    { word: "Colonia", abbreviation: "Col." },
  ];

  // Loop through the replacements array
  for (let i = 0; i < replacements.length; i++) {
    const { word, abbreviation } = replacements[i];

    // Create a regular expression to match the word with word boundaries (\b) and 'g' flag for global replacement
    const regex = new RegExp(`\\b${word}\\b`, "g");

    // Replace all occurrences of the word with its abbreviation in the abbreviated address
    abbreviatedAddress = abbreviatedAddress.replace(regex, abbreviation);
  }

  return abbreviatedAddress;
}

/**
 * Capitalize text with options to capitalize the first letter of each word.
 *
 * @param {string} text - The input text to capitalize.
 * @param {boolean} capitalizeAllWords - Whether to capitalize all words.
 * @param {boolean} isEvent - Whether the text is an event title.
 * @returns {string} The capitalized text.
 */
function capitalize(text, capitalizeAllWords = false, isEvent = false) {
  if (!text) {
    return "";
  }

  if (!capitalizeAllWords) {
    // Capitalize only the first letter of the text
    return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  }

  if (isEvent) {
    // Capitalize an event title with specific rules
    return capitalizeEvent(text);
  }

  // Capitalize all words in the text
  return text
    .split(/[\s-]+/)
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
}

/**
 * Capitalize an event title with specific rules.
 *
 * @param {string} text - The event title to capitalize.
 * @returns {string} The capitalized event title.
 */
function capitalizeEvent(text) {
  if (!text) {
    return "";
  }

  // Handle special characters and formatting
  const formattedText = text.replace(/[\u201C\u201D\u2018\u2019]/g, '"');

  const words = formattedText.split(/[\s-]+/);
  const connectors = [
    "y",
    "o",
    "pero",
    "sin",
    "con",
    "de",
    "del",
    "a",
    "en",
    "al",
    "e",
  ];

  const capitalizedWords = words.map((word, index) => {
    if (connectors.includes(word.toLowerCase())) {
      // Leave connectors in lowercase
      return word.toLowerCase();
    }
    if (word.match(/^(bondup[.?:!]?$|bondup)$/i)) {
      // Handle specific cases for "BondUP"
      const punctuation = word.match(/[.?:!]?$/)[0];
      return "BondUP" + punctuation;
    }
    if (word.match(/^[¿¡]/)) {
      // Handle words starting with "¿" or "¡"
      return word.charAt(0) + word.charAt(1).toUpperCase() + word.slice(2);
    }
    if (
      index > 0 &&
      formattedText.charAt(formattedText.indexOf(word) - 1) === '"'
    ) {
      // Handle words following a double quotation mark
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    }
    if (word.startsWith('"')) {
      // Handle words starting with a double quotation mark
      return '"' + word.charAt(1).toUpperCase() + word.slice(2);
    }
    // Default: Capitalize the first letter of the word
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  return capitalizedWords.join(" ");
}

/**
 * Compare two strings after normalizing them.
 *
 * @param {string} target - The target string for comparison.
 * @param {string} value - The value to compare against.
 * @returns {boolean} True if the normalized target and value are equal; otherwise, false.
 */
function compare(target, value) {
  if (!target || !value) {
    return false;
  }

  // Normalize both the target and value for comparison
  const normalizedTarget = normalize(target);
  const normalizedValue = normalize(value);

  return normalizedTarget === normalizedValue;
}

/**
 * Check if a string includes another string after normalizing them.
 *
 * @param {string} target - The target string to check.
 * @param {string} value - The value to search for.
 * @returns {boolean} True if the normalized target includes the normalized value; otherwise, false.
 */
function includes(target, value) {
  if (!target || !value) {
    return false;
  }

  // Normalize both the target and value for comparison
  const normalizedTarget = normalize(target);
  const normalizedValue = normalize(value);

  return normalizedTarget.includes(normalizedValue);
}

/**
 * Normalize a text string by removing diacritics, special characters,
 * and converting spaces to hyphens while converting to lowercase.
 *
 * @param {string} text - The text string to normalize.
 * @returns {string} The normalized text.
 */
function normalize(text) {
  if (typeof text !== "string") {
    return "";
  }

  // Normalize the text by removing diacritics, special characters, and spaces
  const normalizedText = text
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "") // Remove diacritics
    .replace(/[^\w\s]|_/g, "") // Remove special characters and underscores
    .replace(/\s+/g, "-") // Convert spaces to hyphens
    .toLowerCase(); // Convert to lowercase

  return normalizedText;
}

/**
 * Generate a pluralized string based on the count.
 *
 * @param {number} count - The count used to determine pluralization.
 * @param {string} text - The singular form of the word.
 * @returns {string} The pluralized string based on the count.
 */
function pluralize(count, text) {
  if (typeof text !== "string") {
    return "";
  }

  if (count === 1) {
    return `${count} ${text}`;
  }

  // Apply common English pluralization rules
  if (
    text.endsWith("s") ||
    text.endsWith("x") ||
    text.endsWith("z") ||
    text.endsWith("ch") ||
    text.endsWith("sh")
  ) {
    return `${count} ${text}es`;
  }

  return `${count} ${text}s`;
}

/**
 * Repeat a text string multiple times.
 *
 * @param {string} text - The text to repeat.
 * @param {number} times - The number of times to repeat the text.
 * @returns {string} The repeated text.
 */
function repeat(text, times) {
  if (typeof text !== "string") {
    return "";
  }

  return text.toString().repeat(times);
}

/**
 * Reverse the order of words in a text string.
 *
 * @param {string} text - The text to reverse.
 * @returns {string} The text with reversed word order.
 */
function reverse(text) {
  return text.split(" ").reverse().join(" ");
}

/**
 * Convert a text string to a slug by replacing non-alphanumeric characters with hyphens.
 *
 * @param {string} text - The text to slugify.
 * @returns {string} The slugified text.
 */
function slugify(text) {
  return text
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+/g, "-")
    .replace(/(^-|-$)+/g, "");
}

/**
 * Truncate a text string to a maximum length, adding a suffix if truncated.
 *
 * @param {string} text - The text to truncate.
 * @param {number} maxLength - The maximum length of the truncated text.
 * @param {string} suffix - The suffix to add if the text is truncated.
 * @returns {string} The truncated text.
 */
function truncate(text, maxLength, suffix = "...") {
  if (text.length <= maxLength) {
    return text;
  }
  return text.slice(0, maxLength - suffix.length) + suffix;
}

const string = {
  abbreviate,
  capitalize,
  compare,
  includes,
  normalize,
  pluralize,
  repeat,
  reverse,
  slugify,
  truncate,
};

export default string;
