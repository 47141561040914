"use client";

import CryptoJS from "crypto-js";
import { useCallback } from "react";

function useCrypto() {
  const secretKey = process.env.FIREBASE_API_KEY;

  const encrypt = useCallback(
    (data) => CryptoJS.AES?.encrypt(JSON.stringify(data), secretKey).toString(),
    [secretKey]
  );

  const decrypt = useCallback(
    (data) => {
      if (!data) return;
      const bytes = CryptoJS.AES?.decrypt(data, secretKey);
      return bytes.toString(CryptoJS.enc.Utf8);
    },
    [secretKey]
  );

  return {
    encrypt,
    decrypt,
  };
}

export default useCrypto;
