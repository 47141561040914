import Box from "@mui/material/Box";
import classnames from "classnames";
import PropTypes from "prop-types";

function BupBox({ children, className, hidden, ...props }) {
  if (hidden) return null;

  return (
    <Box className={classnames("BupBox-root", className)} {...props}>
      {children}
    </Box>
  );
}

BupBox.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hidden: PropTypes.bool,
  ...Box.propTypes,
};

export default BupBox;
