"use client";

import { useEffect, useState, useId } from "react";
import { useMedia } from "hooks";
import { getById } from "utils";

function useSticky({ offset }) {
  const { top } = useMedia();
  const [position, setPosition] = useState(null);
  const [sticky, setSticky] = useState(false);
  const id = useId();

  useEffect(() => setPosition(getById(id).getBoundingClientRect().top), [id]);
  useEffect(() => setSticky(top + offset > position), [top, position, offset]);

  return { id, sticky };
}

export default useSticky;
