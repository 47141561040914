import Stack from "@mui/material/Stack";
import classnames from "classnames";
import PropTypes from "prop-types";

function BupStack({ children, className, ...props }) {
  if (props.hidden) return null;

  return (
    <Stack className={classnames("BupStack-root", className)} {...props}>
      {children}
    </Stack>
  );
}

BupStack.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  hidden: PropTypes.bool,
  ...Stack.propTypes,
};

export default BupStack;
